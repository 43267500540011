export const menu_items_mixin = {
  data() {
    return {

      items: [{
          path: '/reportes',
          icon: 'mdi-chart-bar',
          text: 'Reportes',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/reporte-uscis',
          icon: 'mdi-file-chart',
          text: 'Uscis',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/categorias',
          icon: 'mdi-file-document-box-outline',
          text: 'Categorias',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/paquetes-directorio',
          icon: 'mdi-package-variant',
          text: 'Paquetes Directorio',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/productos-app',
          icon: 'mdi-package-variant',
          text: 'Productos APP',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/lista-adverts',
          icon: 'mdi-bullhorn',
          text: 'Adverts',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/ucsis-type',
          icon: 'mdi-flag',
          text: 'Ucsis Type',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/lista-service-center',
          icon: ' mdi-office-building',
          text: 'Service Center',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/servicios',
          icon: 'mdi-checkbox-multiple-marked-circle',
          text: 'Servicios',
          user: ['CONSULTANT_ROLE', 'HANDYMAN_ROLE']
        },
        {
          path: '/lista-servicios',
          icon: 'mdi-checkbox-multiple-marked-circle',
          text: 'Servicios',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/lista-directorio',
          icon: 'mdi-city-variant-outline',
          text: 'Directorio',
          user: ['ADMIN_ROLE']
        },
        {
          path: '/mis-bookings',
          icon: 'mdi-calendar',
          text: 'Citas',
          user: ['CONSULTANT_ROLE', 'HANDYMAN_ROLE']
        },
        {
          path: '/lista-chats',
          icon: 'mdi-chat-processing',
          text: 'Chats',
          user: ['CONSULTANT_ROLE', 'HANDYMAN_ROLE']
        },
        {
          path: '/lista-clientes-potenciales',
          icon: 'mdi-account-card-details',
          text: 'Clientes potenciales',
          user: ['CONSULTANT_ROLE', 'HANDYMAN_ROLE']
        },
        {
          path: '/leads-calificados',
          icon: 'mdi-star',
          text: 'Clientes calificados',
          user: ['CONSULTANT_ROLE', 'HANDYMAN_ROLE']
        },
        {
          path: '/mis-posts',
          icon: 'mdi-blogger',
          text: 'Posts',
          user: ['COLLABORATOR_ROLE']
        },

        {
          path: '/listar-business-location',
          icon: 'mdi-map-marker',
          text: 'Negocio local App',
          user: ['ADMIN_ROLE', 'HAS_PACKAGE', 'business_enable']
        },

        {
          path: '/mis-anuncios',
          icon: 'mdi-city-variant-outline',
          text: 'directorio web',
          user: ['ADMIN_ROLE', 'HAS_PACKAGE', 'business_enable']
        },

        /*{
          path: '/listar-business-location',
          icon: 'mdi-map-marker',
          text: 'Business Location',
          user: ['COLLABORATOR_ROLE', 'business_enable']
        },*/

        {
          path: '/lista-posts',
          icon: 'mdi-blogger',
          text: 'Posts',
          user: ['ADMIN_ROLE']
        },

        {
          path: '/upload-fb-leads',
          icon: 'mdi-facebook',
          text: 'FB leads',
          user: ['ADMIN_ROLE']
        },

        {
          path: '/lista-tickets',
          icon: 'mdi-ticket-account',
          text: 'Tickets',
          user: ['ADMIN_ROLE']
        },

        {
          path: '/mis-tickets',
          icon: 'mdi-ticket',
          text: 'Tickets asignados',
          user: ['ADMIN_ROLE', 'COLLABORATOR_ROLE']
        },
        {
          path: '/lista-mis-tickets',
          icon: 'mdi-ticket',
          text: 'Soporte',
          user: ['HANDYMAN_ROLE', 'CONSULTANT_ROLE']
        },
        {
          path: '/lista-actualizar-precios',
          icon: 'mdi-chart-donut',
          text: 'Ciudades',
          user: ['ADMIN_ROLE']
        },
      ]
    }
  },

  methods: {
    getFeatures() {
      let booking = this.$store.getters.getpackage
      let paquete = booking.packageDirectoryId
      let features = []
      if (booking && paquete && paquete.businessCard) {
        features.push('business_enable')
      }
      return features
    }
  },

  computed: {
    role() {
      let user = this.$store.getters.getuser
      return user.role
    },

    computedheaders() {
      if (this.role != null) {
        let features = this.getFeatures()
        return this.items.filter(x => {
          return x.user.some(user => user == this.role) || x.user.some(user => features.includes(user))
        })
      }
      return []
    }
  }
}